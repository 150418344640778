import * as React from 'react';
import { observer } from 'mobx-react';
import SectionTitle from 'components/SectionTitle';
import { Parse } from 'helpers/Parse';
import Table from 'components/Table';
import _ from 'lodash';
import ActionButton from 'components/ActionButton';
import { CellInfo } from 'react-table';
import { settingsStore, authStore, groupMemberStore, customerRoleStore, CustomerRoleAccessAreaEnum } from 'stores';
import Popup from 'reactjs-popup';
import Form from 'components/Forms/Form';
import { FormState, FieldState } from 'formstate';
import { required } from 'helpers/FormValidation';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import Dropdown, { DropdownOptionInterface } from 'components/Forms/Dropdown';
import Switch from 'components/Forms/Switch';

interface Props {
    admins: Array<Parse.Object>
}

@observer class Administrators extends React.Component<Props> {

    cancelButtonRef: any;

    constructor(props: Props) {
        super(props);

        this.cancelButtonRef = React.createRef();
    }

    form = new FormState({
        selectedUser: new FieldState<string>('').validators(required),
        selectedAccessArea: new FieldState<string | undefined>(undefined),
        limitedAccess: new FieldState(false)
    })

    componentDidMount() {
        const customerId = authStore.customer.id;
        groupMemberStore.getMembers(customerId, true);
        customerRoleStore.getCustomerRoles(customerId);
    }

    removeAdmin = (e: React.MouseEvent, id: string) => {
        if (window.confirm('Revoke admin privileges?')) {
            settingsStore.removeAdmin(id, authStore.customer.id);
        }
    }

    onSubmit = async () => {
        const form = this.form.$;
        const userId = form.selectedUser.$;
        settingsStore.addAdmin(userId, authStore.customer.id);

        if (form.selectedAccessArea.$ || form.limitedAccess.$) {
            await customerRoleStore.createCustomerRole(authStore.customer.id, userId, form.selectedAccessArea.$, form.limitedAccess.$);
        }

        form.selectedUser.reset('');
        form.selectedAccessArea.reset(undefined);
        form.limitedAccess.reset(false);

        this.cancelButtonRef.current.props.onClick(); // To close popup
    }

    mapMembers(members: Array<Parse.Object>) {
        const membersList: Array<DropdownOptionInterface> = [];
        if (!members) return membersList;

        members.forEach((member) => {
            const userId = member.get('user').id;

            if (!!!_.find(membersList, { value: userId }) && !_.find(this.props.admins, { id: userId })) {
                membersList.push({
                    name: member.get('user').get('name') || 'No name',
                    value: userId
                })
            }

        });

        return _.orderBy(membersList, ['name']);
    }

    render() {
        const { admins } = this.props;
        const users = this.mapMembers(groupMemberStore.members);
        const roles = customerRoleStore.roles || [];
        const sortedAdmins = _.orderBy(admins, (u) => u.get('name'));

        const columns = [
            {
                Header: 'Name',
                id: 'name',
                accessor: (item: Parse.Object) => item.get('name')
            },
            {
                Header: 'Access area',
                id: 'accessArea',
                accessor: (item: Parse.Object) => {
                    const allVerticalsStr = 'All verticals';
                    const role = _.find(roles, (cr) => cr.get('user').id === item.id) as Parse.Object || undefined;
                    if (role) {
                        switch (role.get('accessArea')) {
                            case CustomerRoleAccessAreaEnum.MAIN: return 'College Events';
                            case CustomerRoleAccessAreaEnum.FITNESS: return 'Fitness';
                            case CustomerRoleAccessAreaEnum.CLUB_SPORT: return 'Club Sport';
                            case CustomerRoleAccessAreaEnum.BOOKINGS: return 'Bookings only';
                            default: return allVerticalsStr;
                        }

                    }

                    return allVerticalsStr;
                }
            },
            {
                Header: 'Limited access',
                id: 'limitedAccess',
                accessor: (item: Parse.Object) => {
                    const role = _.find(roles, (cr) => cr.get('user').id === item.id) as Parse.Object || undefined;
                    if (role) {
                        return role.get('limitedAccess') ? 'Yes' : 'No'
                    }
                    return 'No';
                }
            },
            {
                Header: '',
                id: 'action',
                width: 120,
                Cell: (cellInfo: CellInfo) => {
                    const item = cellInfo.original as Parse.Object;

                    return (
                        <button
                            className='no-underline text-warning font-medium'
                            onClick={(e) => this.removeAdmin(e, item.id)}>
                            Remove
                        </button>
                    )

                }
            }
        ]

        return (
            <div>
                <SectionTitle title='Administrators' skipMarginBottom={true} />

                <div className='mt-8'>
                    <Table
                        data={sortedAdmins}
                        columns={columns}
                        condensed={true} />

                    {
                        users.length > 0 &&
                        <Popup
                            trigger={<ActionButton text='Add admin' primary={true} width='wrap' className='mt-4' />}
                            modal
                            closeOnDocumentClick>

                            {
                                close => (
                                    <Form onValidSubmit={this.onSubmit} formState={this.form}>
                                        <Section className='mt-4 mb-4'>
                                            <SectionItem titleWidth='140px' title='User'>
                                                <Dropdown
                                                    placeholderText='Select user'
                                                    className='Dropdown-light'
                                                    fieldState={this.form.$.selectedUser}
                                                    options={users} />

                                            </SectionItem>

                                            <SectionItem titleWidth='140px' title='Access area'>
                                                <Dropdown
                                                    placeholderText='All verticals'
                                                    className='Dropdown-light'
                                                    fieldState={this.form.$.selectedAccessArea}
                                                    options={[
                                                        {
                                                            value: CustomerRoleAccessAreaEnum.MAIN,
                                                            name: 'Rec Activities'
                                                        },
                                                        {
                                                            value: CustomerRoleAccessAreaEnum.FITNESS,
                                                            name: 'Fitness'
                                                        },
                                                        {
                                                            value: CustomerRoleAccessAreaEnum.CLUB_SPORT,
                                                            name: 'Club Sport'
                                                        },
                                                        {
                                                            value: CustomerRoleAccessAreaEnum.BOOKINGS,
                                                            name: 'Bookings only'
                                                        }
                                                    ]} />

                                            </SectionItem>

                                            <SectionItem titleWidth='140px' title='Limited access' hideSeparator={true}>
                                                <Switch fieldState={this.form.$.limitedAccess} />
                                            </SectionItem>

                                            <div className='text-right mt-4'>
                                                <ActionButton onClick={close} text='Cancel' width='wrap' className='mr-2' ref={this.cancelButtonRef} />
                                                <ActionButton type='submit' text='Add admin' width='narrow' primary={true} />
                                            </div>
                                        </Section>
                                    </Form>
                                )
                            }

                        </Popup>
                    }
                </div>


            </div>
        )
    }

}

export default Administrators;